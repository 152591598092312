@use "@angular/material" as mat;
@use "sass:map";
@use "theme/feedback.theme.scss" as feedback;
@use "theme/file.theme.scss" as file;
@use "theme/dashboard.theme.scss" as dashboard;
@use "theme/global.theme.scss" as global;
@use "theme/calculation.theme.scss" as calculation;
@use "app/common-components/user-badge/user-badge.theme.scss" as userBadge;
@use "theme/facility.theme.scss" as facility;
@use "theme/inline-input.theme.scss" as inlineInput;
@use "theme/share-dialog.theme.scss" as shareDialog;
@use "theme/admin.theme.scss" as admin;
@use "theme/report.theme.scss" as report;
@use "theme/snackbar.theme.scss" as snackbar;
@use "app/common-components/nista-button/nista-button.theme.scss" as button;
@use "theme/icon-button.theme.scss" as icon-button;
@use "theme/tag.theme.scss" as tag;
@use "app/common-components/nista-tag/nista-tag.theme.scss" as nista-tag;
@use "theme/navigation.theme.scss" as navigation;
@use "app/admin/home/home.theme.scss" as home;
@use "app/admin/edit-user-dialog/edit-user-dialog.theme.scss" as
  edit-user-dialog;
@use "theme/canvasjs.theme.scss" as canvasjs;
@use "app/common-components/update-info-badge/update-info-badge.theme.scss" as
  update-info-badge;
@use "app/common-components/comment-thread/comment.theme.scss" as comment;
@use "app/common-components/nista-inputfield/nista-inputfield.component.theme.scss"
  as nista-inputfield;
@use "app/common-components/nista-inputfield-minimal/nista-inputfield-minimal.component.theme.scss"
  as nista-inputfield-minimal;
@use "app/common-components/page-header/page-header.component.theme.scss" as
  page-header;
@use "app/dashboard/report-detail/report-view/report-content-base/report-smart-meter-content/report-smart-meter-content.theme.scss"
  as report-smart-meter-content;
@use "nista-mixins.scss";
@use "app/common-components/nista-select/nista-select.component.theme.scss" as
  nista-select;
@use "app/common-components/date-picker/date-picker.component.theme.scss" as
  nista-date-picker;
@use "app/common-components/nista-alert/nista-alert.component.theme.scss" as
  nista-alert;
@use "app/common-components/nista-checkbox/nista-checkbox.component.theme.scss"
  as nista-checkbox;
@use "app/common-components/notification-button/notification.theme.scss" as
  nista-notification;
@use "app/common-components/confirm-dialog-display/confirm-dialog.theme.scss" as
  confirm-dialog;
@use "app/common-components/progress-icon/progress-icon.theme.scss" as
  progress-icon;
@use "app/admin/workspace-delete-dialog/workspace-delete-dialog.theme.scss" as
  workspace-delete-dialog;
@use "app/status/status.component.theme.scss" as status-page;
@use "app/common-components/tab-navigation/tab-navigation.theme.scss" as
  tab-navigation;
@use "app/common-components/time-picker/time-picker.theme.scss" as time-picker;
@use "app/dashboard/task-feed/task-feed.theme.scss" as task-feed;
@use "app/workspace-wizard/workspace-wizard.component.theme.scss" as
  workspace-wizard;
@use "app/common-components/workspace-switcher/workspace-switcher.component.theme.scss"
  as worksapce-switcher;
@use "app/common-components/workspace-switcher/create-workspace-popup/create-workspace-popup.component.theme.scss"
  as create-workspace-switcher-popup;
@use "app/common-components/facility-switcher/facility-switcher.component.theme.scss"
  as facility-switcher;
@use "app/common-components/facility-switcher/create-facility-popup/create-facility-popup.component.theme.scss"
  as create-facility-switcher-popup;
@use "app/common-components/dashboard/dashboard.component.theme.scss" as
  app-dashboard;
@use "app/common-components/facility-tag/facility-tag.component.theme" as
  facility-tag;
@use "app/common-components/nista-table/nista-table.component.theme" as
  nista-table;
@use "app/dashboard/violation-list/violation-list.component.theme.scss" as
  nista-violation-list;
@use "app/common-components/nista-trend-icon/nista-trend-icon.theme.scss" as
  nista-trend-icon;
@use "app/common-components/nista-comparison-plus-linechart/nista-comparison-plus-linechart.theme.scss"
  as nista-comparison-plus-linechart;
@use "app/common-components/datapoint-inline-input/datapoint-inline-input.component.theme.scss"
  as datapoint-inline-input;
@use "app/admin/userlist/userlist.theme.scss" as userlist-theme;
@use "app/dashboard/datapoint-statistics/datapoint-statistics.theme.scss" as
  datapoint-statistics-theme;
@use "app/dashboard/report-detail/report-view/report-content-base/report-consumption-enpi-content/consumption-statistics/consumption-statistics.theme.scss"
  as enpi-statistics-theme;
@use "app/dashboard/initatives-board/initiatives-board.theme.scss" as
  initiatives-board-theme;
@use "app/dashboard/datapoint-details/series-datapoint-display/series-datapoint-display.theme.scss"
  as series-datapoint-display;
@use "app/common-components/nista-expander/nista-expander.theme.scss" as
  nista-expander;
@use "app/common-components/unit-selector/unit-selector.theme.scss" as
  unitSelector;
@use "app/dashboard/submetering-group-detail/submetering-group-detail.theme.scss"
  as submetering-group;
@use "app/common-components/solution-thread/solution-thread.theme.scss" as
  solutions-thread;
@use "app/dashboard/report-detail/report-view/report-content-base/report-dashboard-savings/report-dashboard-savings.component.theme.scss"
  as report-dashboard-savings-content;
@use "app/dashboard/visualisation-enpi-chooser/visualisation-enpi-chooser.component.theme.scss"
  as visualisation-enpi-chooser;
@use "app/common-components/datapoint-chooser/datapoint-chooser.component.theme.scss"
  as datapoint-chooser-theme;
@use "app/dashboard/enpi-list/enpi-list.component.theme.scss" as enpi-list-theme;
@use "app/dashboard/analysis-details/analysis-details.component.theme.scss"
  as analysis-details;

$font-config: map.merge(
  mat.define-typography-config(
    //The !important is a workaround to override the default font-size because material styles are applied after our styles
    //Heading definitions
    $headline:
      mat.define-typography-level(
        1.625rem,
        2rem,
        400,
        "Aeroport" !important,
        0em
      ),
    $title:
      mat.define-typography-level(
        1.4375rem,
        2rem,
        400,
        "Aeroport" !important,
        0.0075em
      ),
    $subheading-2:
      mat.define-typography-level(
        1.25rem,
        1.5rem,
        400,
        "Aeroport" !important,
        0.0094em
      ),
    $subheading-1:
      mat.define-typography-level(
        1.125rem,
        1.5rem,
        400,
        "Aeroport" !important,
        0.0067em
      ),
    //Body definitions
    $body-1:
      mat.define-typography-level(
        0.875rem,
        1.25rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    $body-2:
      mat.define-typography-level(
        0.875rem,
        1.25rem,
        600,
        "Oxygen" !important,
        0.0179em
      ),
    $button:
      mat.define-typography-level(
        inherit,
        1.25rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    $caption:
      mat.define-typography-level(
        0.702rem,
        1rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    $input:
      mat.define-typography-level(
        inherit,
        1.25,
        400,
        "Oxygen" !important,
        0.1em
      ),
    //Unused for now
    $display-4:
      mat.define-typography-level(10rem, 2rem, 400, "Aeroport" !important, 0em),
    $display-3:
      mat.define-typography-level(10rem, 2rem, 400, "Aeroport" !important, 0em),
    $display-2:
      mat.define-typography-level(10rem, 2rem, 400, "Aeroport" !important, 0em),
    $display-1:
      mat.define-typography-level(10rem, 2rem, 400, "Aeroport" !important, 0em)
  ),
  (
    //Heading definitions
    "Desktop/Aeroport/xxl/Regular":
      mat.define-typography-level(
        1.625rem,
        2rem,
        400,
        "Aeroport" !important,
        0em
      ),
    "Desktop/Aeroport/xl/Regular":
      mat.define-typography-level(
        1.4375rem,
        2rem,
        400,
        "Aeroport" !important,
        0.0075em
      ),
    "Desktop/Aeroport/lg/Regular":
      mat.define-typography-level(
        1.25rem,
        1.5rem,
        400,
        "Aeroport" !important,
        0.0094em
      ),
    "Desktop/Aeroport/md/Regular":
      mat.define-typography-level(
        1.125rem,
        1.5rem,
        400,
        "Aeroport" !important,
        0.0067em
      ),
    "Desktop/Aeroport/sm/Regular":
      mat.define-typography-level(
        1rem,
        1.5rem,
        400,
        "Aeroport" !important,
        0.0067em !important
      ),
    "Desktop/Aeroport/xs/Regular":
      mat.define-typography-level(
        0.875rem,
        1.5rem,
        400,
        "Aeroport" !important,
        0.0067em !important
      ),
    "Desktop/Aeroport/xxs/Regular":
      mat.define-typography-level(
        0.6875rem,
        1rem,
        400,
        "Aeroport" !important,
        0.0067em !important
      ),
    "Aeroport/xxl/medium":
      mat.define-typography-level(
        1.625rem,
        2rem,
        500,
        "Aeroport" !important,
        0em
      ),
    "Aeroport/xl/medium":
      mat.define-typography-level(
        1.4375rem,
        2rem,
        500,
        "Aeroport" !important,
        0.0075em
      ),
    "Aeroport/lg/medium":
      mat.define-typography-level(
        1.25rem,
        1.5rem,
        500,
        "Aeroport" !important,
        0.0094em
      ),
    "Aeroport/md/medium":
      mat.define-typography-level(
        1.125rem,
        1.5rem,
        500,
        "Aeroport" !important,
        0.0067em
      ),
    "Aeroport/sm/medium":
      mat.define-typography-level(
        1rem,
        1.5rem,
        500,
        "Aeroport" !important,
        0.0067em !important
      ),
    "Aeroport/xs/medium":
      mat.define-typography-level(
        0.875rem,
        1.5rem,
        500,
        "Aeroport" !important,
        0.0067em !important
      ),
    "Aeroport/xxs/medium":
      mat.define-typography-level(
        0.6875rem,
        1rem,
        500,
        "Aeroport" !important,
        0.0067em !important
      ),
    //Body definitions
    "Desktop/Oxygen/xxl/Mono":
      mat.define-typography-level(
        1.125rem,
        1.5rem,
        400,
        "Oxygen-Mono" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xl/Mono":
      mat.define-typography-level(
        1rem,
        1.5rem,
        400,
        "Oxygen-Mono" !important,
        0.0179em
      ),
    "Desktop/Oxygen/lg/Mono":
      mat.define-typography-level(
        0.9375rem,
        1.5rem,
        400,
        "Oxygen-Mono" !important,
        0.0179em
      ),
    "Desktop/Oxygen/md/Mono":
      mat.define-typography-level(
        0.875rem,
        1.25rem,
        400,
        "Oxygen-Mono" !important,
        0.0179em
      ),
    "Desktop/Oxygen/sm/Mono":
      mat.define-typography-level(
        0.75rem,
        1rem,
        400,
        "Oxygen-Mono" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xs/Mono":
      mat.define-typography-level(
        0.6875rem,
        1rem,
        400,
        "Oxygen-Mono" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xxs/Mono":
      mat.define-typography-level(
        0.625rem,
        1rem,
        400,
        "Oxygen-Mono" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xxl/Regular":
      mat.define-typography-level(
        1.125rem,
        1.5rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xxl-high/Regular":
      mat.define-typography-level(
        1.125rem,
        1.75rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xl/Regular":
      mat.define-typography-level(
        1rem,
        1.5rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/lg/Regular":
      mat.define-typography-level(
        0.9375rem,
        1.5rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/md/Regular":
      mat.define-typography-level(
        0.875rem,
        1.25rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/sm/Regular":
      mat.define-typography-level(
        0.75rem,
        1rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xs/Regular":
      mat.define-typography-level(
        0.6875rem,
        1rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xxs/Regular":
      mat.define-typography-level(
        0.625rem,
        1rem,
        400,
        "Oxygen" !important,
        0.0179em
      ),
    //Body definitions
    "Desktop/Oxygen/xxl/Bold":
      mat.define-typography-level(
        1.125rem,
        1.5rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xxl-high/Bold":
      mat.define-typography-level(
        1.125rem,
        1.75rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xl/Bold":
      mat.define-typography-level(
        1rem,
        1.5rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/lg/Bold":
      mat.define-typography-level(
        0.9375rem,
        1.5rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/md/Bold":
      mat.define-typography-level(
        0.875rem,
        1.25rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/sm/Bold":
      mat.define-typography-level(
        0.75rem,
        1rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xs/Bold":
      mat.define-typography-level(
        0.6875rem,
        1rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/xxs/Bold":
      mat.define-typography-level(
        0.625rem,
        1rem,
        700,
        "Oxygen" !important,
        0.0179em
      ),
    "Desktop/Oxygen/alternativ-xxl/Regular":
      mat.define-typography-level(1.625rem, 2rem, 400, "Oxygen" !important, 0em)
  )
);

@include mat.core($font-config);

@import "@angular/material/theming";

html {
  //Reference for rem so most text will change relative to this
  font-size: 16px !important;
}

h1,
.mat-h1,
.mat-headline,
.heading-xxl {
  @include mat-typography-level-to-styles($font-config, "headline");
}

h2,
.mat-h2,
.mat-title,
.heading-xl {
  @include mat-typography-level-to-styles($font-config, "title");
}

h3,
.mat-h3,
.mat-subheading-2,
.heading-lg {
  @include mat-typography-level-to-styles($font-config, "subheading-2");
}

h4,
.mat-h4,
.mat-subheading-1,
.heading-md {
  @include mat-typography-level-to-styles($font-config, "subheading-1");
}

h5,
.mat-h5,
.heading-sm {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Aeroport/sm/Regular"
  );
}

h6,
.mat-h6,
.heading-xs {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Aeroport/xs/Regular"
  );
}

.heading-xxs {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Aeroport/xxs/Regular"
  );
  margin: 0 0 12px;
}

.heading-md-medium {
  @include mat-typography-level-to-styles($font-config, "Aeroport/md/medium");
}

.heading-lg-medium {
  @include mat-typography-level-to-styles($font-config, "Aeroport/lg/medium");
}

.heading-xl-medium {
  @include mat-typography-level-to-styles($font-config, "Aeroport/xl/medium");
}

.heading-xxl-medium {
  @include mat-typography-level-to-styles($font-config, "Aeroport/xxl/medium");
}

.body-xxl {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/xxl/Regular"
  );
}

.body-xl {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/xl/Regular"
  );
}

.body-lg {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/lg/Regular"
  );
}

.body-md {
  @include mat-typography-level-to-styles($font-config, "body-1");
}

.body-md-regular {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/md/Regular"
  );
}

.body-xl-regular {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/xl/Regular"
  );
}

.body-sm {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/sm/Regular"
  );
}

.body-lg-mono {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/lg/Mono"
  );
}

.body-md-mono {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/md/Mono"
  );
}

.body-sm-mono {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/sm/Mono"
  );
}

.body-xs {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/xs/Regular"
  );
}

.body-xxs {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/xxs/Regular"
  );
}

.oxygen-heading-xxl {
  @include mat-typography-level-to-styles(
    $font-config,
    "Desktop/Oxygen/alternativ-xxl/Regular"
  );
}

.body-bold {
  font-weight: 600 !important;
}

$brand-primary-color: (
  50: #fdedea,
  100: #fbdcd4,
  150: #f9cabf,
  200: #f7b9a9,
  300: #f3957f,
  400: #ef7254,
  500: #eb4f29,
  600: #bc3f21,
  700: #8d2f19,
  800: #5e2010,
  850: #47180c,
  900: #2f1008,
  950: #180804,
  contrast: (
    50: #000000,
    100: #000000,
    150: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    850: #ffffff,
    900: #ffffff,
    950: #ffffff,
  ),
);

$UI-primary-color: (
  50: #eaeffe,
  100: #d4dffd,
  150: #bfcffc,
  200: #a9bffb,
  300: #7e9ff9,
  400: #537ff7,
  500: #285ff5,
  600: #204cc4,
  700: #183993,
  800: #102662,
  850: #0c1d4a,
  900: #081331,
  950: #040a19,
  contrast: (
    50: #000000,
    100: #000000,
    150: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    850: #ffffff,
    900: #ffffff,
    950: #ffffff,
  ),
);

$greyscale-color: (
  A100: #ffffff,
  50: #f2f2f2,
  100: #e5e5e5,
  150: #d8d8d8,
  200: #cbcbcb,
  300: #979797,
  400: #777777,
  500: #646464,
  600: #4c4c4c,
  700: #393939,
  800: #2f2f2f,
  850: #242424,
  900: #171717,
  950: #131313,
  1000: #040404,
  contrast: (
    A100: #171717,
    50: #171717,
    100: #171717,
    150: #171717,
    200: #171717,
    300: #131313,
    400: #131313,
    500: #f2f2f2,
    600: #e5e5e5,
    700: #e5e5e5,
    800: #e5e5e5,
    850: #e5e5e5,
    900: #d8d8d8,
    950: #d8d8d8,
    1000: #d8d8d8,
  ),
);

$error-system-color: (
  50: #feebeb,
  100: #fdd7d7,
  150: #fcc4c4,
  200: #fbb0b0,
  300: #fa8888,
  400: #f86161,
  500: #f63939,
  600: #c62e2e,
  700: #962222,
  800: #671717,
  850: #4f1111,
  900: #370b0b,
  950: #1f0606,
  contrast: (
    50: #000000,
    100: #000000,
    150: #000000,
    200: #000000,
    250: #000000,
    300: #000000,
    350: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    850: #ffffff,
    900: #ffffff,
    950: #ffffff,
  ),
);

$warning-system-color: (
  50: #fff3e6,
  100: #ffe8cc,
  150: #ffdcb3,
  200: #ffd099,
  300: #ffb966,
  400: #ffa133,
  500: #ff8a00,
  600: #cc6e00,
  700: #995300,
  800: #663700,
  850: #4d2900,
  900: #331c00,
  950: #1a0e00,
  contrast: (
    50: #000000,
    100: #000000,
    150: #000000,
    200: #000000,
    250: #000000,
    300: #000000,
    350: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    850: #ffffff,
    900: #ffffff,
    950: #ffffff,
  ),
);

$success-system-color: (
  50: #bff7c4,
  100: #aaeeb0,
  150: #94e69c,
  200: #7fdd88,
  300: #55cd61,
  400: #2abc39,
  500: #00ab11,
  600: #00890e,
  700: #00670a,
  800: #004407,
  850: #003305,
  900: #002203,
  950: #001102,
  contrast: (
    50: #000000,
    100: #000000,
    150: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    850: #ffffff,
    900: #ffffff,
    950: #ffffff,
  ),
);

$info-system-color: (
  50: #eaf6fe,
  100: #d5ecfe,
  150: #c1e3fd,
  200: #acd9fc,
  300: #82c7fb,
  400: #59b4f9,
  500: #2fa1f8,
  600: #2681c6,
  700: #1c6195,
  800: #134063,
  850: #0e304a,
  900: #092032,
  950: #051019,
  contrast: (
    50: #000000,
    100: #000000,
    150: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    850: #ffffff,
    900: #ffffff,
    950: #ffffff,
  ),
);

$secondary-color: (
  power: #ffd60a,
  energy: #f8961e,
  pressure: #d94a93,
  ratio: #ff6b6b,
  temperature: #ff4858,
  mass-flow-rate: #8c3fed,
  force: #8c009f,
  currency: #9ef8ee,
  mass: #5acdff,
  time: #4895ef,
  velocity: #0551e5,
  distance: #02c6d1,
  area: #28ebab,
  volume: #01df68,
  electrical-current: #23ff67,
  electrical-potential: #1a9032,
  xxx: #36ce00,
  volume-flow-rate: #aee647,
);

$transparent: (
  5: rgba(255, 255, 255, 0),
  10: rgba(255, 255, 255, 0.04),
  20: rgba(255, 255, 255, 0.08),
  30: rgba(255, 255, 255, 0.12),
  40: rgba(255, 255, 255, 0.16),
  50: rgba(255, 255, 255, 0.2),
);

$primary: mat.define-palette($brand-primary-color);
$accent: mat.define-palette($UI-primary-color);
$warn: mat.define-palette($warning-system-color);
$success: mat.define-palette($success-system-color);
$error: mat.define-palette($error-system-color);
$info: mat.define-palette($info-system-color);
$greyscale: mat.define-palette($greyscale-color);

$theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
      success: $success,
      error: $error,
      greyscale: $greyscale,
    ),
  )
);

// Dark Theme text
$light-text: mat.get-color-from-palette($greyscale, 50);
$light-primary-text: $light-text;
$light-accent-text: mat.get-color-from-palette($greyscale, 100);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);
$container-border: mat.get-color-from-palette($transparent, 40);

$dark-theme-foreground-palette: (
  base: $light-text,
  container-border: $container-border,
  divider: rgba($light-dividers, 0.12),
  dividers: rgba($light-dividers, 0.12),
  disabled: rgba($light-disabled-text, 0.5),
  disabled-button: rgba($light-text, 0.3),
  disabled-text: rgba($light-disabled-text, 0.5),
  elevation: black,
  hint-text: rgba($light-disabled-text, 0.5),
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Dark bg
$dark-background: mat.get-color-from-palette($greyscale, 950);
$dark-bg-lighter-5: mat.get-color-from-palette($greyscale, 850);
$dark-bg-lighter-10: mat.get-color-from-palette($greyscale, 800);
$dark-bg-lighter-20: mat.get-color-from-palette($greyscale, 700);
$dark-bg-lighter-30: mat.get-color-from-palette($greyscale, 300);
$dark-bg-container-0: mat.get-color-from-palette($transparent, 5);
$dark-bg-container-1: mat.get-color-from-palette($greyscale, 950);
$dark-bg-container-2: mat.get-color-from-palette($greyscale, 900);
$dark-bg-container-3: mat.get-color-from-palette($greyscale, 850);
$dark-bg-container-4: mat.get-color-from-palette($greyscale, 800);
$dark-bg-container-widget: mat.get-color-from-palette($greyscale, 1000);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$dark-theme-background-palette: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: rgba($light-focused, 0.12),
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
  dark-bg-lighter-5: $dark-bg-lighter-5,
  dark-bg-lighter-10: $dark-bg-lighter-10,
  dark-bg-lighter-20: $dark-bg-lighter-20,
  dark-bg-lighter-30: $dark-bg-lighter-30,
  bg-container-0: $dark-bg-container-0,
  bg-container-1: $dark-bg-container-1,
  bg-container-2: $dark-bg-container-2,
  bg-container-3: $dark-bg-container-3,
  bg-container-4: $dark-bg-container-4,
  bg-container-widget: $dark-bg-container-widget,
  bg-container-popupmenu: $dark-bg-container-3,
);

$color: map.get($theme, "color");
$background: map.get($color, "background");
$foreground: map.get($color, "foreground");
$secondary: map.merge((), $secondary-color);
$transparency: map.merge((), $transparent);

$background: map.merge($background, $dark-theme-background-palette);

$foreground: map.merge($foreground, $dark-theme-foreground-palette);

$color: map.merge(
  $color,
  (
    "background": $background,
    "foreground": $foreground,
    "secondary": $secondary,
    "greyscale": $greyscale,
    "error": $error,
    "info": $info-system-color,
    "success": $success-system-color,
    "transparency": $transparency,
  )
);
$theme: map.merge(
  $theme,
  (
    "color": $color,
    "typography": $font-config,
  )
);

@include mat.all-component-themes($theme);
@include feedback.theme($theme);
@include file.theme($theme);
@include dashboard.theme($theme);
@include global.theme($theme);
@include calculation.theme($theme);
@include userBadge.theme($theme);
@include inlineInput.theme($theme);
@include shareDialog.theme($theme);
@include admin.theme($theme);
@include report.theme($theme);
@include snackbar.theme($theme);
@include update-info-badge.theme($theme);
@include nista-inputfield.theme($theme);
@include nista-inputfield-minimal.theme($theme);
@include button.theme($theme);
@include icon-button.theme($theme);
@include tag.theme($theme);
@include nista-tag.theme($theme);
@include navigation.theme($theme);
@include comment.theme($theme);
@include solutions-thread.theme($theme);
@include nista-select.theme($theme);
@include nista-alert.theme($theme);
@include page-header.theme($theme);
@include nista-notification.theme($theme);
@include nista-checkbox.theme($theme);
@include confirm-dialog.theme($theme);
@include facility.theme($theme);
@include home.theme($theme);
@include report-smart-meter-content.theme($theme);
@include workspace-delete-dialog.theme($theme);
@include status-page.theme($theme);
@include tab-navigation.theme($theme);
@include time-picker.theme($theme);
@include nista-date-picker.theme($theme);
@include task-feed.theme($theme);
@include workspace-wizard.theme($theme);
@include progress-icon.theme($theme);
@include worksapce-switcher.theme($theme);
@include create-workspace-switcher-popup.theme($theme);
@include facility-switcher.theme($theme);
@include create-facility-switcher-popup.theme($theme);
@include app-dashboard.theme($theme);
@include facility-tag.theme($theme);
@include canvasjs.theme($theme);
@include nista-table.theme($theme);
@include nista-violation-list.theme($theme);
@include nista-trend-icon.theme($theme);
@include nista-comparison-plus-linechart.theme($theme);
@include datapoint-inline-input.theme($theme);
@include userlist-theme.theme($theme);
@include edit-user-dialog.theme($theme);
@include datapoint-statistics-theme.theme($theme);
@include enpi-statistics-theme.theme($theme);
@include initiatives-board-theme.theme($theme);
@include nista-expander.theme($theme);
@include series-datapoint-display.theme($theme);
@include unitSelector.theme($theme);
@include submetering-group.theme($theme);
@include report-dashboard-savings-content.theme($theme);
@include visualisation-enpi-chooser.theme($theme);
@include datapoint-chooser-theme.theme($theme);
@include enpi-list-theme.theme($theme);
@include analysis-details.theme($theme);